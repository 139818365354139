
:root{
  /*COLORS*/
  --color-sidebar: #121212;
  --color-page: hsl(var(--hue),10%,10%);
  --color-theme: #303030;
  --color-nav-text: #ffffff;
  --color-nav-hover: #505050;

	--bg: hsl(var(--hue),10%,10%);
	--hue: 223;

  /*SIZE*/
  --svg-size: 1.5rem;
  --font-size: 1rem;
}

.layout-box{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction:row;
    font-family: 'Google Sans';
}

/* SIDEBAR */
.sidebar{
    width: 16rem;
    height: 100%;
    background-color: var(--color-sidebar);
}

.sidebar-logo{
  width: 100%;
  height: 3.5rem;
  padding-bottom: 1rem;
}

#logo{
  float: left;
  height: 60%;
  padding: 1rem 1.8rem;
}

#sidebar-title{
  float: left;
  margin-top: 12px;
  margin-left: 35px;
  font-family: 'Mona Sans';
  font-size: 4vh;
}

.sidebar-content{
  width: 100%;
  height: calc(100% - 8.2rem);
}

.home-sidebar-link-box{
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.sidebar-link{
  display: flex;
  gap: 0.7rem;
  align-items: center;
  width: calc(100% - 4rem);
  margin: 0rem 0.75rem 0.7rem;
  padding: 0.5rem 1rem;
  background-color: var(--color-theme);
  border-radius: 5px;
  color: var(--color-nav-text);
  font-size: var(--font-size);
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s;
}

.sidebar-link:hover{
  text-decoration: none;
  background-color: var(--color-nav-hover);
}

.sidebar-link-svg{
  width: var(--svg-size);
  height: var(--svg-size);
}

/* ACCOUNT */
.sidebar-account{
  width: 100%;
  height: 2.3rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  display: flex;
  align-items: center;
}

#user-photo-link{
  height: 100%;
  margin-left: auto;
  margin-right: 1.1rem;
}

#user-photo{
  height: 100%;
  border-radius: 50%;
}

main{
    flex: 1;
}