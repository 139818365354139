:root{
    --FONTSIZE: 2.6vh;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@font-face {
    font-family: 'Mona Sans';
    src:
      url('../../lib/fonts/Mona-Sans.woff2') format('woff2 supports variations'),
      url('../../lib/fonts/Mona-Sans.woff2') format('woff2-variations');
    font-weight: 200 500;
    font-stretch: 75% 125%;
}

.lockscreen-main{
    height: 100%;
    background: linear-gradient(-45deg, #000fff, #0079ff, #34a3ff, #00159e);
    background-size: 600% 600%;
    animation: gradient 15s ease infinite;
    height: 2000px;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

/* ====[ NAVBAR ]===============================*/

.lockscreen-navbar-c {
    background: transparent;
    width: 100%;
    height: 70px;
}

.lockscreen-navbar-left{
    float: left;
    height: 100%;
    width: 40%;
    margin-left: 10%;
}

.lockscreen-navbar-right{
    float: right;
    height: 100%;
    width: 40%;
    margin-right: 10%;
}

.right-navbar-item{
    float: right;
    padding-left: 5%;
    padding-top: 2.5%;
}

.left-navbar-item{
    float: left;
    padding-right: 5%;
    padding-top: 2.5%;
}

#navbar-logo{
    padding-top: 1.5%;
}

.navbar-logo-link{
    display: inline-block;
    height: 50px;
}

.lockscreen-navbar-button{
    display: inline-block;
    text-align: center;
    color: white;
    text-decoration: none;
    background-color: transparent;
    width: 200px;
    height: 4vh;
    font-family: Poppins;
    font-size: var(--FONTSIZE);
    border-radius: 10px;
    transition: all 0.5s;
    cursor: pointer;
}

.lockscreen-login-button{
    display: inline-block;
    text-align: center;
    color: white;
    text-decoration: none;
    background-color: transparent;
    border: 2px solid rgba(255,255,255,0.5);
    width: 150px;
    height: 4vh;
    font-family: Poppins;
    font-size: var(--FONTSIZE);
    border-radius: 10px;
    transition: all 0.5s;
    cursor: pointer;
}

.lockscreen-login-button:hover{
    background-color: blue;
    text-decoration: none;
}

.lockscreen-navbar-button:hover{
    background-color: blue;
    text-decoration: none;
}


/* ====[ MAIN PAGE ]=============================*/

.lockscreen-central-c {
    background: transparent;
    width: 100%;
    overflow-y: scroll;
}

.lockscreen-display-c{
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 15%;
}

.lockscreen-title{
    font-size: 10vh;
    font-family:'Mona Sans';
}

.lockscreen-text{
    font-size: 6vh;
    font-family:'Mona Sans';
}