.pm-main{
    display: flex;
    width: 100%;
    height: 100%;
    background-color: var(--color-page);
    align-items: center;
    justify-content: center;
}

.pm-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 26rem;
    height: 26rem;
    border-radius: 2rem;
    background-color: var(--color-theme);
    padding: 1rem;
}

.pm-title-box{
    display: flex;
    width: 100%;
    height: 2rem;
    padding-top: 1.25rem;
    padding-bottom: 0.5rem;
}

#pm-back-link{
    width: 2rem;
    height: 2rem;
}

#pm-back{
    width: 100%;
    height: 100%;
}

#pm-title{
    margin: 0;
    padding: 0 3rem;
    text-align: center;
    color: white;
    font-size: 1.5rem;
    text-transform: uppercase;
}

.pm-item{
    width: 24rem;
    height: 6rem;
    border-radius: 1rem;
    background-color: white;
    padding: 1rem;
    padding-bottom: 0.5rem
}

#pm-button{
    width: 50%;
    height: 3rem;
    border: white 2px solid;
    border-radius: 0.5rem;
    color: white;
    background-color: transparent;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Google Sans';
    cursor: pointer;  
}

#pm-button:hover{
    border: var(--color-theme) 2px solid;
    background-color: white;
    color: var(--color-theme);
}

.pm-item-title{
    margin: 0 1rem;
    padding: 0.5rem 0;
}

#pm-username-input{
    width: 21.8rem;
    height: 2rem;
    border: var(--color-theme) 2px solid;
    border-radius: 1rem;
    outline: none;
    font-size: 1rem;
    padding: 0.2rem 1rem;
}

#pm-username-error{
    margin: 0;
    text-align: center;
    width: 100%;
    padding: 0.15rem 0;
    color: red;
}

#pm-color-input{
    height: 2rem;
    width: 4rem;
    margin-left: 10rem;
    border: var(--color-theme) 2px solid;
    border-radius: 0.5rem;
    outline: none;
    font-size: 1rem;
}
