@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.register-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(-45deg, #000fff, #0079ff, #34a3ff, #00159e);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

.register-logo{
    width: 100px;
    margin-top: -10vh;
    margin-bottom: 2vh;
    padding-top: 10vh;
}

.register-form-box{
    width: 400px;
    height: 500px;
    background: transparent;
    border: 2px solid rgba(255,255,255,0.5);
    border-radius: 20px;
    backdrop-filter: blur(15px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Poppins;
}

.register-h2{
    font-size: 2em;
    color: #fff;
    text-align: center;
}
.register-inputbox{
    position: relative;
    margin: 30px 0;
    width: 310px;
    border-bottom: 2px solid #fff;
}
.register-inputbox .register-label{
    position: absolute;
    top: 0.5vh;
    left: 5px;
    transform: translateY(-50%);
    color: #fff;
    font-size: 1em;
    pointer-events: none;
    transition: .5s;
}
register-label{
top: -5px;
}
.register-inputbox input {
    width: 100%;
    height: 50px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1em;
    padding:0 35px 0 5px;
    color: #fff;
}
.register-inputbox ion-icon{
    position: absolute;
    right: 8px;
    color: #fff;
    font-size: 1.2em;
    top: 20px;
}
.register-forget{
    margin: -15px 0 15px ;
    font-size: .9em;
    color: #fff;
    display: flex;
    justify-content: space-between;  
}

.register-forget register-label register-input{
    margin-right: 3px;
}

.register-forget register-label register-p{
    color: #fff;
    text-decoration: none;
}
.register-forget register-label register-p{
    text-decoration: underline;
}

.register-button{
    width: 100%;
    height: 40px;
    color: white;
    border-radius: 40px;
    background: transparent;
    border: 3px outset #ffff;
    outline: none;
    cursor: pointer;
    transition: all 0.5s;
    font-size: 1em;
    font-weight: 600;
    margin-top: 1em;
}

.register-button:hover{
    background: #fff;
    color: black;
}

.register-register{
    font-size: .9em;
    color: #fff;
    text-align: center;
    margin: 25px 0 10px;
}
.register-register a{
    text-decoration: none;
    color: #fff;
    font-weight: 600;
}
.register-register a:hover{
    text-decoration: underline;
}

#reme{
    float: right;
    padding-left: 4em;
}

.register-google-login{
    margin-left: 4vh;
}


@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}