.pr-main{
    display: flex;
    width: 100%;
    height: 100%;
    background-color: var(--color-page);
    align-items: center;
    justify-content: center;
}

.pr-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 26rem;
    height: 32rem;
    border-radius: 2rem;
    background-color: var(--color-theme);
    padding: 1rem;
}

.pr-title-box{
    display: flex;
    width: 100%;
    height: 2rem;
    padding-top: 1.25rem;
    padding-bottom: 0.5rem;
}

#pr-back-link{
    width: 2rem;
    height: 2rem;
}

#pr-back{
    width: 100%;
    height: 100%;
}

#pr-title{
    width: 22rem;
    margin: 0;
    padding: 0;
    text-align: center;
    color: white;
    font-size: 1.5rem;
    text-transform: uppercase;
}

.pr-item{
    width: 24rem;
    min-height: 5rem;
    border-radius: 1rem;
    background-color: white;
    padding: 1rem;
}

#pr-button{
    width: 50%;
    height: 3rem;
    border: white 2px solid;
    border-radius: 0.5rem;
    color: white;
    background-color: transparent;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Google Sans';
    cursor: pointer;  
}

#pr-button:hover{
    border: var(--color-theme) 2px solid;
    background-color: white;
    color: var(--color-theme);
}

.pr-item-title{
    margin: 0 1rem;
    padding: 0.5rem 0;
}

.pr-select{
    width: 7rem;
    height: 2rem;
    margin: 0.5rem 1rem;
    padding: 0 1rem;
    background-color: transparent;
    border: var(--color-theme) 2px solid;
    border-radius: 0.5rem;
    font-family: "Google Sans";
    font-size: 1rem;
}

.pr-select option {
    font-family: "Google Sans";
}

#pr-justif-input{
    width: 21.8rem;
    height: 10rem;
    border: var(--color-theme) 2px solid;
    border-radius: 1rem;
    outline: none;
    font-size: 1rem;
    font-family: "Google Sans";
    padding: 0.5rem 1rem;
    resize: none;
}