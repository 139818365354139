
:root {
	--hue: 223;
    --bg: hsl(var(--hue),10%,10%);
    --fg: hsl(var(--hue),10%,90%);
	--trans-dur: 0.3s;
}
.loading-fp-main {
    display: flex;
    align-items: center;
    justify-content: center;
	background-color: var(--bg);
	color: var(--fg);
	height: 100%;
    width: 100%;
	transition: background-color var(--trans-dur), color var(--trans-dur);
}

.lfp_pl {
	margin: auto;
	width: 12em;
	height: 12em;
}
.lfp_pl__drop, .lfp_pl__drop-inner, .lfp_pl__pan, .lfp_pl__ring, .lfp_pl__shadow {
	animation: pan 2s cubic-bezier(0.65,0,0.35,1) infinite;
}
.lfp_pl__drop {
	transform-origin: 13px 60px;
}
.lfp_pl__drop-inner {
	animation-timing-function: cubic-bezier(0.33,0.84,0.67,0.84);
}
.lfp_pl__drop--1 {
	animation-name: drop-1;
}
.lfp_pl__drop--1 .lfp_pl__drop-inner {
	animation-name: drop-1-inner;
}
.lfp_pl__drop--2 {
	animation-name: drop-2;
}
.lfp_pl__drop--2 .lfp_pl__drop-inner {
	animation-name: drop-2-inner;
}
.lfp_pl__drop--3 {
	animation-name: drop-3;
	transform-origin: 67px 72px;
}
.lfp_pl__drop--3 .lfp_pl__drop-inner {
	animation-name: drop-3-inner;
}
.lfp_pl__drop--4 {
	animation-name: drop-4;
	transform-origin: 67px 72px;
}
.lfp_pl__drop--4 .lfp_pl__drop-inner {
	animation-name: drop-4-inner;
}
.lfp_pl__drop--5 {
	animation-name: drop-5;
	transform-origin: 67px 72px;
}
.lfp_pl__drop--5 .lfp_pl__drop-inner {
	animation-name: drop-5-inner;
}
.lfp_pl__pan {
	transform-origin: 36px 74px;
}
.lfp_pl__ring {
	animation-name: flip-ring;
}
.lfp_pl__shadow {
	animation-name: pan-shadow;
	transform-origin: 36px 124.5px;
}


/* Animation */
@keyframes drop-1 {
	from {
		animation-timing-function: steps(1,end);
		transform: translate(0,0);
		visibility: hidden;
	}
	30% {
		animation-timing-function: linear;
		transform: translate(0,0);
		visibility: visible;
	}
	50%, to {
		transform: translate(-6px,0);
	}
}
@keyframes drop-1-inner {
	from, 30% {
		fill: hsl(223,10%,70%,1);
		transform: translate(0,0);
	}
	50%, to {
		fill: hsl(223,10%,70%,0);
		transform: translate(0,-27px);
	}
}
@keyframes drop-2 {
	from {
		animation-timing-function: steps(1,end);
		transform: translate(0,0);
		visibility: hidden;
	}
	30% {
		animation-timing-function: linear;
		transform: translate(0,0);
		visibility: visible;
	}
	50%, to {
		transform: translate(-8px,0);
	}
}
@keyframes drop-2-inner {
	from, 30% {
		fill: hsl(223,10%,70%,1);
		transform: translate(0,0);
	}
	50%, to {
		fill: hsl(223,10%,70%,0);
		transform: translate(0,-9px);
	}
}
@keyframes drop-3 {
	from {
		animation-timing-function: steps(1,end);
		transform: translate(0,0);
		visibility: hidden;
	}
	78% {
		animation-timing-function: linear;
		transform: translate(0,0);
		visibility: visible;
	}
	98%, to {
		transform: translate(-24px,0);
	}
}
@keyframes drop-3-inner {
	from, 78% {
		fill: hsl(223,10%,70%,1);
		transform: translate(0,0);
	}
	98%, to {
		fill: hsl(223,10%,70%,0);
		transform: translate(0,-28px);
	}
}
@keyframes drop-4 {
	from {
		animation-timing-function: steps(1,end);
		transform: translate(0,0);
		visibility: hidden;
	}
	78% {
		animation-timing-function: linear;
		transform: translate(0,0);
		visibility: visible;
	}
	98%, to {
		transform: translate(-8px,0);
	}
}
@keyframes drop-4-inner {
	from, 78% {
		fill: hsl(223,10%,70%,1);
		transform: translate(0,0);
	}
	98%, to {
		fill: hsl(223,10%,70%,0);
		transform: translate(0,-36px);
	}
}
@keyframes drop-5 {
	from {
		animation-timing-function: steps(1,end);
		transform: translate(0,0);
		visibility: hidden;
	}
	78% {
		animation-timing-function: linear;
		transform: translate(0,0);
		visibility: visible;
	}
	98%, to {
		transform: translate(8px,0);
	}
}
@keyframes drop-5-inner {
	from, 78% {
		fill: hsl(223,10%,70%,1);
		transform: translate(0,0);
	}
	98%, to {
		fill: hsl(223,10%,70%,0);
		transform: translate(0,-32px);
	}
}
@keyframes flip-ring {
	from, 27% {
		animation-timing-function: cubic-bezier(0.33,0.84,0.67,0.84);
		stroke-dashoffset: 20;
		stroke-width: 4px;
	}
	53.5% {
		animation-timing-function: cubic-bezier(0.33,0.16,0.67,0.16);
		stroke-dashoffset: -100;
		stroke-width: 10px;
	}
	80%, to {
		stroke-dashoffset: -220;
		stroke-width: 4px;
	}
}
@keyframes pan {
	from, 88%, to {
		transform: translate(0,0) rotate(0);
	}
	20% {
		animation-timing-function: cubic-bezier(0.33,0.16,0.67,0.16);
		transform: translate(-5px,0) rotate(-30deg);
	}
	30% {
		animation-timing-function: cubic-bezier(0.33,0.84,0.67,0.84);
		transform: translate(0,0) rotate(20deg);
	}
	60%, 78% {
		animation-timing-function: linear;
		transform: translate(0,0) rotate(0);
	}
	81.33% {
		animation-timing-function: linear;
		transform: translate(0,4px) rotate(0);
	}
	84.67% {
		animation-timing-function: linear;
		transform: translate(0,-2px) rotate(0);
	}
}
@keyframes pan-shadow {
	from, 88%, to {
		fill: hsla(223,10%,50%,0.2);
		transform: scaleX(1);
	}
	20% {
		animation-timing-function: cubic-bezier(0.33,0.16,0.67,0.16);
		fill: hsla(223,10%,50%,0.2);
		transform: scaleX(0.77);
	}
	30% {
		animation-timing-function: cubic-bezier(0.33,0.84,0.67,0.84);
		fill: hsla(223,10%,50%,0.2);
		transform: scaleX(1);
	}
	60%, 78% {
		animation-timing-function: linear;
		fill: hsla(223,10%,50%,0.2);
		transform: scaleX(1);
	}
	81.33% {
		animation-timing-function: linear;
		fill: hsla(223,10%,50%,0.25);
		transform: scaleX(0.87);
	}
	84.67% {
		animation-timing-function: linear;
		fill: hsla(223,10%,50%,0.225);
		transform: scaleX(1.065);
	}
}
