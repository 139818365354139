.cvsns4-main{
    background-color: var(--color-page);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-family: 'Google Sans';
}

.cvsns4-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50rem;
    height: 30rem;
    background-color: var(--color-theme);
    border-radius: 1rem;
}

/*
.cvsns4-form input{
    border-radius: 0.2rem;
    border: 2px solid grey;
    padding: 0.2rem;
}
*/

.cvsns4-form-input{
    display: flex;
    flex-direction: column;
    width: 48rem;
    height: 20rem;
    padding: 1rem;
}

.cvsns4-form-top{
    display: flex;
    width: 100%;
    height: 10rem;
}

.cvsns4-persos{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
    height: 100%;
}
.cvsns4-perso{
    width: 15rem;
    margin-bottom: 1rem;
}

.cvsns4-form-bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.cvsns4-form-bottom-layer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 2rem;
}

#cvsns4-map{
    width: 14rem;
}

.cvsns4-score{
    height: 1rem;
    width: 2rem;
}

#cvsns4-score-separator{
    padding: 0 1rem;
}

#cvsns4-note{
    width: 1rem;
}

#cvsns4-winner{
    width: 7rem;
    margin-left: 2rem;
}

.cvsns4-form-button{
    width: 5rem;
    margin: 0 1rem;
}


