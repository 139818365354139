:root{
    --gold: #ffb338;
    --light-shadow: #77571d;
    --dark-shadow: #3e2904;
}

.profile-main-box{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: var(--color-page);
    overflow-y: scroll;
}

.profile-top-box{
    width: 58rem;
    height: 33rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    background-color: var(--color-theme);
    border-radius: 2rem;
}

/* TOP BAR */

.profile-top-c{
    display: flex;
    justify-content: space-between;
    height: 20%;
    width: 92%;
    padding: 2% 4%;
}

.profile-top-user{
    display: flex;
    align-items: center;
    width: 50%;
    height: 100%;
}

#profile-user-photo{
    border-radius: 20%;
    height: 100%;
}

.profile-user-name{
    margin: 0;
    padding: 0 10%;
    color: white;
    font-size: 2rem;
    font-weight: 500;
}

.profile-top-card{
    display: grid;
    grid-template-areas: "overlap";
    place-content: center;
    text-transform: uppercase;
    font-size: 3rem;
}

.profile-top-card>div{
    background-clip: text;
    -webkit-background-clip: text;
    font-weight: 900;
    grid-area: overlap;
    letter-spacing: 1px;
    -webkit-text-stroke: 4px transparent;
}

#profile-card-bg{
    background-image: repeating-linear-gradient(105deg, var(--gold)0%, var(--dark-shadow)5%, var(--gold)12%);
    color: transparent;
    filter: drop-shadow(5px 15px 15px rgb(40, 40, 40));
    transform: scaleY(1.05);
    transform-origin: top;
}

#profile-card-fg{
    background-image: repeating-linear-gradient(5deg, var(--gold)0%, var(--light-shadow)23%, var(--gold)31%);
    color: rgb(245, 245, 245);
    transform: scaleY(1);
    transform-origin: top;
}

#profile-card{
    color: white;
    font-size: 2rem;
}

.profile-content-box{
    display: flex;
    width: 94%;
    height: 74%;
    padding: 3%;
    background-color: hsl(0, 0%, 90%);
    border-radius: 2rem;
}

/* CONTENT */
/* Attributes */
.profile-attributes{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 50%;
    height: 100%;
    padding-left: 1rem;
}

.profile-attribute-box{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    font-size: 1rem;
}

.profile-key{
    min-width: 5.5rem;
    color: var(--color-theme);
    text-transform: uppercase;
}

.profile-value{
    color: black;
}

.profile-theme-display{
    height: 100%;

}

/* Modify */
.profile-modify{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 50%;
    align-items: flex-end;
    justify-content: flex-end;
}

.profile-modify-item{
    width: 10rem;
    height: 1.3rem;
    padding: 0.3rem;
    text-align: end;
    border-radius: 0.5rem;
}

.profile-modify-item:hover{
    background-color: var(--color-theme);
    color: white;
}

.profile-modify-link{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    text-decoration: none;
    color: var(--color-theme);
}

.profile-modify-link:hover{
    color: white;
}

.profile-modify-text{
    margin: 0;
}

.profile-logout-button{
    border: none;
    font-size: 1rem;
    color: var(--color-theme);
    background-color: transparent;
    cursor: pointer;
}

.profile-logout-button:hover{
    color: white;
}
