@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.privacy-main{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    font-size: 2.2vh;
    color: white;
    background-color: var(--color-page);
}

.privacy-central-c {
    padding: 0 5rem;
}

.privacy-title-c{
    /* background-color: red; */
    /* opacity: 50%; */
    width: 100%;
    height: 10%;
    padding-top: 0.1%;
    padding-bottom: 1%;
    text-align: center;
}

.privacy-title{
    height: 55%;
}

.privacy-content-c{
    width: 100%;
    height: 90%;
    padding-left: 1%;
    display: inline-flex;
}
