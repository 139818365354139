@import url('https://fonts.googleapis.com/css2?family=Google+Sans&display=swap');

.home-main{
  background-color: var(--color-page);
  display: flex;
  width: 100%;
  height: 100%;
  font-family: 'Google Sans';
}

