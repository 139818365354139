.forum-box{
    width: 100%;
    height: 100%;
    background-color: var(--color-page);
    color: white;
    display: flex;
    flex-direction: column;
}

.forum-chat-box{
    display: flex;
    height: 100%;
    width: 45rem;
}

.forum-chat{
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
    width: 45rem;
    padding: 0 calc((100% - 45rem)/2);
    height: calc(100% - 5.5rem);
    overflow-y: scroll;
}

.forum-msg-box{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.5rem;
}

#forum-msg-pfp{
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
}

.forum-msg-sent{
    display: flex;
    margin-left: auto;
}

.forum-msg-received{
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.forum-msg-sent-content{
    max-width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.2rem;
    padding: 0.5rem 0.7rem;
    border-radius: 1rem;
    background-color: var(--color-theme);
}

.forum-msg-received-content{
    max-width: 20rem;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    padding: 0.5rem 0.7rem;
    border-radius: 1rem;
    background-color: var(--color-theme);
}

.forum-msg-username{
    margin: 0 0.5rem;
    font-size: calc(0.97 * var(--font-size));
}

.forum-msg-text{
    margin: 0;
    font-size: calc(0.97 * var(--font-size));
}

.forum-msg-time{
    font-size: 0.7rem;
    margin: 0;
}

/* INPUT */
.forum-input-box{
    display: flex;
    gap: .4rem;
    width: 43.25rem;
    height: 2.5rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    margin-left: calc((100% - 45rem)/2);
    margin-right: calc((100% - 45rem)/2);
    padding: .25rem;
    padding-left: 1.5rem;
    background-color: rgb(100, 100, 100);
    border-radius: 1.5rem;
}

.forum-input{
    margin: 0;
    padding: 0;
    width: calc(100% - 1.15rem);
    background-color: transparent;
    height: 100%;
    border: none;
    outline: none;
    font-size: inherit;
    color: white;
}

.forum-send-button{
    height: 100%;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.forum-send-svg{
    height: 100%;
}