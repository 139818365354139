@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

:root{
    --blank: 10%;
    --border-color: var(--color-theme);
    --card-background-color: rgb(42, 42, 42);
}

.game-main{
    display: flex;
    width: 100%;
    height: 100%;
    background-color: var(--color-page);
    color: white;
    justify-content: center;
}

.game-central-c {
    width: 60rem;
}

.game-title-c{
    display: flex;
    width: 100%;
    height: 10rem;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.game-title{
    display: flex;
    align-items: center;
    height: 55%;
    font-family: Poppins;
}

.game-content-c{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5rem;
}

.game-list-c{
    width: 40rem;
    border: 5px solid var(--border-color);
    background-color: var(--card-background-color);
    border-radius: 10px;
}

.game-description-c{
    width: 15rem;
    height: 8rem;
    border: 5px solid var(--border-color);
    background-color: var(--card-background-color);
    border-radius: 10px;
    padding: 1rem;
}

.description{
    margin: 0;
    font-family: Poppins;
    font-size: 1rem;
}

table{
   width: 100%;
   padding: 2%;
   font-size: 1rem;
}

th{
    text-align: left;
    padding-bottom: 2%;
}

td{
    padding-bottom: 2%;
}

.game-link{
    color: white;
    text-decoration: none;
    background-color: transparent;
    border: 2px solid transparent;
    font-family: Poppins;
    border-radius: 10px;
    padding: 1.5%;
    transition: all 0.5s;
    cursor: pointer;
}

.game-link:hover{
    background-color: var(--color-theme);
    color: white;
    text-decoration: none;
}
